<template>
	<div>
		<PageTitle title="药品物流" fixed backTo="/home"></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<List :width="100">
				<ListItem v-for="(item,index) in items" :key="index" :to="'/logisticsDetails/' + item.jzxh">
					<template #top>
						<p style="text-align: center;padding: 10px 0">状态：{{item.wlzt}}</p>
						<div class="borderColor">
							<div class="leftArea">
								<p class="fontmd">药品名称：{{item.logisticsList[0].ypxxes[0].ypmc}}</p>
								<p class="fontsm" style="margin-top: 10px">收货人：{{item.sjrxm}} {{item.phone}}</p>
								<p class="fontsm" v-show="item.sjrxm != '来院自取'">
									收货地址：{{item.sjrsf}}&nbsp;{{item.sjrqs}}&nbsp;{{item.sjrxj}}&nbsp;{{item.sjrzj}}</p>
								<p class="fontsm" v-show="item.sjrxm != '来院自取'">{{item.sjrxxdz}}</p>
								<p class="fontsm" style="margin-top: 10px">物流状态：{{item.wlzt}}</p>
								<p class="fontsm">物流单号：{{item.kddh}}</p>
							</div>
							<div class="rightArea">
								<p class="fontsm">
									X{{item.logisticsList[0].ypxxes[0].ypsl}}{{item.logisticsList[0].ypxxes[0].yfdw}}
								</p>
								<van-icon name="arrow" />
							</div>
						</div>
					</template>
				</ListItem>
				<p style="text-align: center;font-size:14px;color: #3f9775" v-show="(showMore == 1)">暂无更多物流信息</p>
			</List>
		</div>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		GetDrugLogistics,
		getDefaultAccount
	} from '@/api/user.js';


	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				items: [],
				index: 1,
				size: 10,
				cardNum: '',
				searchStatus: true,
				showMore: 0,
			}
		},
		created() {
			this.getData();
			this.itemScroll();
		},
		beforeDestroy() {
			document.removeEventListener("scroll", this.itemScroll);
		},
		methods: {
			itemScroll() {
				let that = this;
				window.onscroll = function() {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
					let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
					if (scrollTop + windowHeight + 50 >= scrollHeight && that.searchStatus) {
						that.searchStatus = false;
						that.searchData();
					}
				}
			},
			getData() {
				this.index = 1;
				getDefaultAccount(this.$store.getters.userInfo.UserId).then(
					(result) => {
						this.cardNum = result.ckhm;
						this.searchData();
					},
					() => {}
				);
			},
			searchData() {
				if (this.index > 0) {
					GetDrugLogistics(this.cardNum, this.index, this.size).then(
						(result) => {
							if (result.length > 0) {
								this.items = this.items.concat(result);
								this.index++;
								this.searchStatus = true;
							} else {
								this.index = -1;
								this.searchStatus = false;
								this.showMore = 1;
							}
						},
						() => {
							this.index = -1;
							this.searchStatus = false;
							this.showMore = 1;
						}
					);
				}
			}
		},

	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.infoContainer {
		padding-bottom: 10px;

		p {
			margin: 0;
		}

		.borderColor {
			background-color: #f6f6f6;
			border-radius: 8px;
			padding: 10px;
			margin: 0 10px;

			.leftArea {
				width: 87%;
				display: inline-block;

				p:first-child {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden; //隐藏超出单元格的部分。
					/*white-space: nowrap; //保证文本内容不会自动换行，如果多余的内容会在水平方向撑破单元格。*/
					/*text-overflow: ellipsis; //将被隐藏的那部分用省略号代替。*/
				}

			}

			.rightArea {
				width: 13%;
				display: inline-block;
				vertical-align: top;

				p {
					float: right;
				}

				.van-icon {
					float: right;
					margin-top: 40px;
				}
			}
		}

		.fontmd {
			font-size: @font-size-md;
		}

		.fontsm {
			font-size: @font-size-sm;
		}
	}
</style>
